import React from 'react';
import { useTranslation } from 'react-i18next';

import { TRANSLATIONS_OPTIONS, MESSAGE_OPTIONS} from '../../shared/constants';
import IconWhatsApp from '../../media/icons/icon-whatsapp.svg';

import styles from './whats-app-button.module.scss';

function WhatsAppButton() {
  const { t, i18n } = useTranslation('global');
  const currentTranslation = i18n.language;

  return (
      <div className={styles['whats-button']}>
        <a
          className={styles['whats-button__link']}
          href={currentTranslation === TRANSLATIONS_OPTIONS.EN ?
            MESSAGE_OPTIONS.eng : MESSAGE_OPTIONS.esp}
        >
          <img
            src={IconWhatsApp}
            className={styles['whats-button__icon']}
            alt="Whats App icon"
            height={20}
          />
          <span>{t('whatsButton.title')}</span>
        </a>
      </div>
  )
}

export default WhatsAppButton;
