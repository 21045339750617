import { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Nav from '../nav/nav';
import Button from '../../shared/button/button';

import { TRANSLATIONS_OPTIONS } from '../../shared/constants';

import IconCorehlance from '../../media/icons/logo-corehlance.svg';
import IconMenu from '../../media/icons/icon-menu.svg';
import styles from './header.module.scss';

export default function Header({windowSize, windowWidth, setTermsNConditions}) {
  const {t, i18n} = useTranslation('global');
  const currentTranslation = i18n.language;
  const [showIconMenu, setShowIconMenu] = useState(false);
  const [showBigMenu, setShowBigMenu] = useState(false);

  const handleTranlations = language => {
    i18n.changeLanguage(language);
    localStorage.removeItem('terms-corehlance');
    setTermsNConditions();
  }

  const handleMenu = () => {
    if (windowWidth <= 1000){
      setShowIconMenu(true);
    } else {
      setShowIconMenu(false);
    }
  }

  const handleActionButtonMenu = (value) => {
    setShowBigMenu(value);
  }

  useEffect(() => {
    handleMenu();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  const sizeImage = useMemo(() => {
    const auxParam = SIZE_IMAGE.find(param => param.size === windowSize);
    return auxParam?.measure || 30;
  }, [windowSize]);

  return (
    <div className={styles.header}>
      <div className={clsx(styles['big_menu'],{
        [styles['big_menu--active']]: showBigMenu
      })}>
        <div className={styles['button_menu']}>
          <Button kind="ghost" action={() => handleActionButtonMenu(false)}>
            <img className={styles.menu} src={IconMenu} alt="Icon logo" height={20}/>
            
          </Button>
        </div>
        <Nav  vertical windowSize={windowSize} handleChecked={() => handleActionButtonMenu(false)}/>
      </div>
      <div className={styles['top-content']}>
        <div className={styles.logo}>
          <img
            src={IconCorehlance}
            alt="Corehlance logo"
            height={sizeImage}
          />
        </div>
        {!showIconMenu
          ? <Nav windowSize={windowSize}/>
          : null
        }
        
        <div className={styles.translations}>
          {showIconMenu ? (
            <Button kind="ghost" action={() => handleActionButtonMenu(true)}>
              <img className={styles.menu} src={IconMenu} alt="Icon logo" height={25}/>
            </Button>
          ) : null}
          <button 
            className={clsx(styles['button-translation'], {
              [styles['button-translation--current']]: currentTranslation === TRANSLATIONS_OPTIONS.ES
            })}
            onClick={() => handleTranlations(TRANSLATIONS_OPTIONS.ES)}
          >
            {t('tranlations.spanish')}</button>
          <button
            className={clsx(styles['button-translation'], {
              [styles['button-translation--current']]: currentTranslation === TRANSLATIONS_OPTIONS.EN
            })}
            onClick={() => handleTranlations(TRANSLATIONS_OPTIONS.EN)}
          >
            {t('tranlations.english')}
          </button>
        </div>
        
      </div>
      <div className={styles.content}>
        <p>
          <span className={styles.title}>{t('header.title')}</span>
        </p>
        <Button kind="primary" size="large">
          <a href="#about-us">{t('header.buttonMore')}</a>
        </Button>
      </div>
    </div>
  )
}

const SIZE_IMAGE = [
  {
    size: 'default',
    measure: 50,
  },
  {
    size: 'large',
    measure: 50,
  },
  {
    size: 'medium',
    measure: 40,
  },
  {
    size: 'small',
    measure: 30,
  },
  {
    size: 'xsamll',
    measure: 20,
  },
];