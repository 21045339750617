import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import SocialMedia from '../social-media/social-media';
import { SECTION_LIST } from '../../shared/constants';

import IconLogo from '../../media/icons/icon-logo.svg';

import styles from './footer.module.scss';

function Footer({windowWidth}) {
  const { t } = useTranslation('global');

  return (
    <div className={styles.footer} id="contact">
      <div className={styles['footer__form']}>
        <span className={styles['footer__form-title']}>{t('footer.form.title')}</span>
        <div>
          <form action="https://formsubmit.co/ventas.noreste@corehlance.com" method="POST">
            <input
              placeholder={t('footer.form.name')}
              className={styles['footer__input']}
              type="text"
              name="name"
            />
            <div className={styles['footer__md-cont']}>
              <input
                className={clsx(styles['footer__input'],{
                  [styles['footer__input--half']]: windowWidth > 512
                })}
                placeholder={t('footer.form.company')}
                type="text"
                name="company"
              />
              <input
                className={clsx(styles['footer__input'],{
                  [styles['footer__input--half']]: windowWidth > 512
                })}
                placeholder={t('footer.form.phone')}
                type="tel"
                name="phone"
              />
            </div>
            <div className={styles['footer__md-cont']}>
              <input
                className={clsx(styles['footer__input'],{
                  [styles['footer__input--half']]: windowWidth > 512
                })}
                placeholder={t('footer.form.email')}
                type="email"
                name="email"
              />
              <select
                name="service"
                className={clsx(styles['footer__input'],{
                  [styles['footer__input--half']]: windowWidth > 512
                })}
              >
                <option value="" disabled selected hidden>{t('footer.form.service')}</option>
                {SECTION_LIST.map(item => (
                  <option
                    value={t(`services.content.${item.title}`)}
                  >{t(`services.content.${item.title}`)}</option>
                ))}
              </select>
            </div>
            <textarea
              cols="50"
              rows="10"
              className={styles['footer__textarea']}
              name="comments"
              placeholder={t('footer.form.message')}
            />
            <input type="submit" value={t('footer.form.send')} className={styles['footer__submit']}/>
            <input type="hidden" name="_next" value="https://corehlance.com/" />
            <input type="hidden" name="_captcha" value="false"/>
          </form>
        </div>
      </div>
      <div className={styles['footer__info']}>
        <div className={styles.description}>
          <img
            src={IconLogo}
            className={styles.logo}
            alt="Corehlance logo"
            height={windowWidth <= 512 ? 70 : 50}
          />
          <div className={styles.noms}>
            <p className={styles.title}>{t('footer.noms.title')}</p>
            <p className={styles['description__content']}>{t('footer.noms.content')}</p>
          </div>
        </div>
        <div className={styles.contact}>
          <div className={styles.column}>
            <p className={styles.title}>{t('footer.address.title')}</p>
            <p className={styles['contact__content']}>
              <span>{t('footer.address.street')}</span>
              <span>{t('footer.address.neighborhood')}</span>
              <span>{t('footer.address.state')}</span>
            </p>
          </div>
          <div className={styles.column}>
            <p className={styles.title}>{t('footer.contact.title')}</p>
            <div>
              <span className={styles['contact__content']}>
                <a href="mailto:ventas.noreste@corehlance.com">ventas.noreste@corehlance.com</a>
              </span>
              <span>
                <a href="tel:+528123217906">+52 81 2321 7906</a>
              </span>
              <SocialMedia />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
