import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import AliceCarousel from 'react-alice-carousel';

import imagePrevArrow from '../../media/icons/icon-left.svg';
import imageNextArrow from '../../media/icons/icon-right.svg';

import { TRANSLATIONS_OPTIONS, MESSAGE_OPTIONS} from '../../shared/constants';

import styles from './packages.module.scss';

const PACKAGES_LIST = [
  {
    id: uuidv4(),
    title: 'basic',
  },
  {
    id: uuidv4(),
    title: 'standard',
  },
  {
    id: uuidv4(),
    title: 'premium',
  },
];

function Packages({windowWidth}) {
  const { t, i18n } = useTranslation('global');
  const language = i18n.language;

  const urlMessage = useMemo(() =>
    language === TRANSLATIONS_OPTIONS.EN ? MESSAGE_OPTIONS.eng : MESSAGE_OPTIONS.esp,
    [language]
  );

  const listItems = PACKAGES_LIST.map(element => (
    <div  className={styles['main-package']} key={element.id}>
      <div className={styles.package}>
        <span className={styles['package__title']}>
          {t(`packages.${element.title}`)}
        </span>
        <button className={'button-primary button-primary__medium'}>
          <a href={urlMessage}>
            {t('packages.buttonMore')}
          </a>
        </button>
      </div>
    </div>
  ));

  const responsive = {
    0: {
      items: 1
    },
    700: {
      items: 2
    }
  };

  return (
    <div className={styles.packages} id="packages">
      <h3 
        className={styles['packages__title']}
      >
        {t('packages.title')}
      </h3>
      <div className={styles.content}>
        {windowWidth > 670  ? 
          listItems : 
          <AliceCarousel
            disableDotsControls
            infinite
            items={listItems}
            responsive={responsive}
            renderPrevButton={() => {
              return (<img className={styles.controls} src={imagePrevArrow} height={35} alt="prev"/>)
            }}
            renderNextButton={() => {
              return (<img className={styles.controls} src={imageNextArrow} height={35} alt="next"/>)
            }}
          />
        }
      </div>
    </div>
  )
}

export default Packages;
