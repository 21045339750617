import clsx from "clsx";

import styles from './button.module.scss';

function Button({
  children,
  kind='primary',
  size = 'default',
  border = false,
  hoverCyan = false,
  action = () => null,
  ...props
}) {
  return (
    <button
      {...props}
      className={clsx(styles.button, {
        [styles['button-primary']]: kind === 'primary',
        [styles['button-ghost']]: kind === 'ghost',
        [styles['button-ghost-hover-cyan']]: hoverCyan,
        [styles['button-primary__border']]: border,
        [styles['button-ghost__border']]: border,
        [styles['button--fluid']]: size === 'fluid',
        [styles['button--xxs']]: size === 'xxs',
        [styles['button--xs']]: size === 'xs',
        [styles['button--small']]: size === 'small',
        [styles['button--medium']]: size === 'medium',
        [styles['button--large']]: size === 'large',

      })}
      onClick={() => action()}
    >
      {children}
    </button>
  )
}

export default Button
