import { useTranslation } from 'react-i18next';

import Carousel from '../carousel/carousel';

import styles from './services.module.scss';

function Services() {
  const { t } = useTranslation('global');

  return (
    <div className={styles.services} id="services">
      <h3 className={styles['services__title']}>
        {t('services.title')}
      </h3>

      <Carousel />

      <div className={styles.advice}>
        <span className={styles['services__subtitle']}>
          <sup>*</sup>{t('services.subtitle')}
        </span>
      </div>
    </div>
  )
}

export default Services
