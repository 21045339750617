import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';

import Button from '../../shared/button/button';
import { TRANSLATIONS_OPTIONS } from '../../shared/constants';

import pdfEng from '../../media/pdf/privacy-notice-eng.pdf';
import pdfEsp from '../../media/pdf/privacy-notice-esp.pdf';

import styles from './modal.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Modal({windowWidth, setTermsNConditions}) {
  const [termsWidth, setTermsWidth] = useState();
  const [numPages, setNumPages] = useState(null);
  const { t, i18n } = useTranslation('global');
  const language = i18n.language;
  
  const pdfFile = useMemo(() =>
    language === TRANSLATIONS_OPTIONS.EN ? pdfEng : pdfEsp,
    [language]
  );

  const handleTermsAndConditions = (value) => {
    localStorage.setItem('terms-corehlance', value);
    setTermsNConditions(value);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    switch (true) {
      case windowWidth > 1024:
        setTermsWidth(780);
        break;
      case windowWidth > 768 && windowWidth <= 1024:
        setTermsWidth(600);
        break;
      case windowWidth > 640 && windowWidth <= 768:
        setTermsWidth(450);
        break;
      case windowWidth > 400 && windowWidth <= 640:
        setTermsWidth(350);
        break;
      case windowWidth <= 400:
        setTermsWidth(windowWidth - 60);
        break;
      default:
        setTermsWidth(500);
        break;
    }
  }, [windowWidth])

  return(
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles['terms-and-conditions']}>
          <Document
            file={pdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
            className={styles['terms-and-conditions__pdf-container']}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                className="pdf-page"
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                customTextRenderer={false}
                width={termsWidth}
              />
            ))}
          </Document>
        </div>
        <div className={styles.controls}>
          <Button
            kind="primary"
            size="medium"
            action={() => handleTermsAndConditions('disagree')}
          >
            {t('modal.btnDisagree')}
          </Button>
          <Button
            kind="primary"
            size="medium"
            action={() => handleTermsAndConditions('agree')}
          >
            {t('modal.btnAgree')}
          </Button>
        </div>
      </div>
    </div>
  );  
}

export default Modal;
