import { useState, useEffect } from 'react';
import i18next from 'i18next';
import {I18nextProvider } from "react-i18next";

import Modal from './components/modal/modal';
import WhatsAppButton from './components/whats-app-button/whats-app-button';
import FixedContact from './components/fixed-contact/fixed-contact';
import Header from './components/header/header';
import AboutUs from './components/about-us/about-us';
import Services from './components/services/services';
import SpecialPrograms from './components/special-programs/special-programs';
import Packages from './components/packages/packages';
import Footer from './components/footer/footer';
import Strengths from './components/strengths/strengths';
import Certifications from './components/certifications/certifications';

import { WINDOW_SIZE } from './shared/constants';

import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';

import './App.scss';

i18next.init({
  interpolation: { escapeValue: false},
  lng: 'es',
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    },
  },
});

function App() {
  const [windowWidth, setWindowWidth] = useState();
  const [windowSize, setWindowSize] = useState('default');
  const [termsNConditions, setTermsNConditions] = useState();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const termsCorehlance = localStorage.getItem('terms-corehlance');
    setTermsNConditions(termsCorehlance);
  }, []);

  useEffect(() => {
    switch (true) {
      case windowWidth > 1284:
        setWindowSize(WINDOW_SIZE.large);
        break;
      case windowWidth > 768 && windowWidth <= 1284:
        setWindowSize(WINDOW_SIZE.medium);
        break;
      case windowWidth > 512 && windowWidth <= 768:
        setWindowSize(WINDOW_SIZE.small);
        break;
      case windowWidth <= 512:
        setWindowSize(WINDOW_SIZE.xsmall);
        break;
      default:
        setWindowSize(WINDOW_SIZE.default);
        break;
    }
  }, [windowWidth]);

  return (
    <div className="App">
      <I18nextProvider i18n={i18next}>
        <div>
          {!termsNConditions ? (
            <Modal
              windowWidth={windowWidth}
              setTermsNConditions={setTermsNConditions}
            />
          ): null }
          <FixedContact />
          <WhatsAppButton/>
          <Header
            windowSize={windowSize}
            windowWidth={windowWidth}
            setTermsNConditions={setTermsNConditions}/>
          <AboutUs/>
          <Services/>
          <SpecialPrograms />
          <Packages windowWidth={windowWidth}/>
          <Strengths />
          <Certifications windowWidth={windowWidth}/>
          <Footer windowWidth={windowWidth}/>
        </div>
      </I18nextProvider>
    </div>
  );
}

export default App;
