import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import Column from './column';
import styles from './special-programs.module.scss';

function SpecialPrograms() {
  const { t } = useTranslation('global');
  const FIRST_COLUMN = PROGRAMS_LIST.slice(0,4);
  const SECOND_COLUMN = PROGRAMS_LIST.slice(4);

  return (
    <div className={styles['special-programs']}>
      <div className={styles['title-container']}>
        <h3>{t('specialPrograms.title')}</h3>
      </div>
      <div className={styles['wrapper-programs']}>
        <Column list={FIRST_COLUMN}/>
        <Column list={SECOND_COLUMN}/>
      </div>
    </div>
  )
}

export default SpecialPrograms;

const PROGRAMS_LIST = [
  {
    id: uuidv4(),
    name: 'thanatology',
    column: 1,
  },
  {
    id: uuidv4(),
    name: 'brigades',
    column: 1,
  },
  {
    id: uuidv4(),
    name: 'riskFactor',
    column: 1,
  },
  {
    id: uuidv4(),
    name: 'healthcareManagement',
    column: 1,
  },
  {
    id: uuidv4(),
    name: 'medicalEquipment',
    column: 2,
  },
  {
    id: uuidv4(),
    name: 'hospitalPartnerships',
    column: 2,
  }
]
