import { useTranslation } from 'react-i18next';

import IconInsta from '../../media/icons/icon-instagram.svg';
import IconFace from '../../media/icons/icon-facebook.svg';
import IconWhatsApp from '../../media/icons/icon-whatsapp.svg';
import IconLinkedIn from '../../media/icons/icon-linkedin.svg';

import { TRANSLATIONS_OPTIONS, MESSAGE_OPTIONS} from '../../shared/constants';

import styles from './social-media.module.scss';

function SocialMedia() {
  const { i18n } = useTranslation('global');
  const currentTranslation = i18n.language;

  return (
    <div className={styles.icons}>
      <span className={styles.icon}>
        <a href="https://instagram.com/corehlance?igshid=MzRlODBiNWFlZA==">
          <img
            src={IconInsta}
            className={styles['logo_media']}
            alt="Instagram icon"
            height={20}
          />
        </a>
      </span>
      <span className={styles.icon}>
        <a href="https://www.facebook.com/profile.php?id=100095523787153&mibextid=LQQJ4d">
          <img
            src={IconFace}
            className={styles['logo_media']}
            alt="Facebook icon"
            height={20}
          />
        </a>
      </span>
      <span className={styles.icon}>
        <a href={currentTranslation === TRANSLATIONS_OPTIONS.EN ? MESSAGE_OPTIONS.eng : MESSAGE_OPTIONS.esp}>
          <img
            src={IconWhatsApp}
            className={styles['logo_media']}
            alt="Whats App icon"
            height={20}
          />
        </a>
      </span>
      <span className={styles.icon}>
        <a href="https://www.linkedin.com/company/corehlance/">
          <img
            src={IconLinkedIn}
            className={styles['logo_media']}
            alt="Facebook icon"
            height={20}
          />
        </a>
      </span>
    </div>
  );
}

export default SocialMedia;
