import { useTranslation } from 'react-i18next';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import imagePrevArrow from '../../media/icons/icon-left.svg';
import imageNextArrow from '../../media/icons/icon-right.svg';

import { SECTION_LIST } from '../../shared/constants';

import styles from './carousel.module.scss';

const itemsDefinition = {
  0: {
    items: 1,
  },
  1024: {
      items: 3,
      itemsFit: 'contain',
  }
}


function Carousel() {
  const { t } = useTranslation('global');

  const listItems = SECTION_LIST.map(item => (
    <div
      key={item.id}
      className={styles['item_container']}
    >
      <div
        className={`${styles.item} ${styles[`item__${item.order}`]}`}
      >
        <p className={styles['item__content']}>
          <span>{t(`services.content.${item.title}`)}</span>
        </p>
        <span className={styles['item__order']}>
          {item.order}
        </span>
      </div>
    </div>
  ))

  return (
    <div className={styles.carousel}>
      <AliceCarousel
        mouseTracking
        disableDotsControls
        autoWidth
        autoHeight
        infinite
        items={listItems}
        renderPrevButton={() => {
          return (<img className={styles.controls} src={imagePrevArrow} height={35} alt="prev"/>)
        }}
        renderNextButton={() => {
          return (<img className={styles.controls} src={imageNextArrow} height={35} alt="next"/>)
        }}
        responsive= {itemsDefinition}
      />
    </div>
  )
}

export default Carousel;
