import { useTranslation } from 'react-i18next';

import styles from './about-us.module.scss';

function AboutUs() {
  const { t } = useTranslation('global');

  return (
    <div className={styles['about-us']} id="about-us">
      <h3 
        className={styles['about-us__title']}
      >
        {t('aboutUs.title')}
      </h3>
      <p
        className={styles['about-us__content']}
      >
        {t('aboutUs.content')}
      </p>
    </div>
  )
}

export default AboutUs
