import { useTranslation } from 'react-i18next';

import SocialMedia from '../social-media/social-media';

import styles from './fixed-contact.module.scss';

function FixedContact() {
  const { t } = useTranslation('global');

  return (
    <div className={styles['fixed-contact']}>
      <div>
        <span className={styles['contact-text']}>
          {t('fixedContact.contact')}
        </span>
        <span>
          <a href="tel:+528123217906">+52 81 2321 7906</a>
        </span>
      </div>
      <SocialMedia />
    </div>
  )
}

export default FixedContact;
