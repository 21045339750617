import { useTranslation } from 'react-i18next';

import styles from './special-programs.module.scss';

function Column({list}) {
  const { t } = useTranslation('global');

  return (
    <div className={styles.column}>
      <ul>
        {list.map(program => (
          <div className={styles.program} key={program.id}>
            <li>
              <span>{t(`specialPrograms.content.${program.name}`)}</span>
            </li>
          </div>
        ))}
      </ul>
    </div>
  )
}

export default Column
