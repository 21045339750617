import { Trans, useTranslation } from 'react-i18next';

import styles from './strengths.module.scss';

export default function Strengths() {
  const { t } = useTranslation('global');

  return (
    <div className={styles.strengths} id="strengths">
      <h3 className={styles['strengths__title']}>
        {t('strengths.title')}
      </h3>

      <div>
        <div className={styles['strengths__paragraph']}>
          <Trans components={{ span: <span /> }}>{t('strengths.firstParagraph')}</Trans>
        </div>
        <div className={styles['strengths__paragraph']}>
          <Trans components={{ span: <span /> }}>{t('strengths.secondParagraph')}</Trans>
        </div>
        <div className={styles['strengths__paragraph']}>
          <Trans components={{ span: <span /> }}>{t('strengths.thirdParagraph')}</Trans>
        </div>
      </div>
    </div>
  )
}
