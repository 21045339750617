import { v4 as uuidv4 } from 'uuid';

export const TRANSLATIONS_OPTIONS = {
  ES: 'es',
  EN: 'en',
  DEFAULT: 'es',
};

export const WINDOW_SIZE = {
  default: 'default',
  xsmall: 'xsmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
}

export const MESSAGE_OPTIONS = {
  esp: 'https://wa.me/8123217906?text=Me%20gustaría%20recibir%20más%20información',
  eng: 'https://wa.me/8123217906?text=%20I%20would%20like%20to%20get%20more%20information'
}

export const SECTION_LIST = [
  {
    id: uuidv4(),
    order: "01",
    title: "mobileUnit",
  },
  {
    id: uuidv4(),
    order: "02",
    title: "mobileLab",
  },
  {
    id: uuidv4(),
    order: "03",
    title: "mobileXRay",
  },
  {
    id: uuidv4(),
    order: "04",
    title: "preplacementExams",
  },
  {
    id: uuidv4(),
    order: "05",
    title: "periodicHealthExams",
  },
  {
    id: uuidv4(),
    order: "06",
    title: "wellnessProgram",
  },
  {
    id: uuidv4(),
    order: "07",
    title: "specializedPersonal",
  },
  {
    id: uuidv4(),
    order: "08",
    title: "telemedicineServices",
  },
  {
    id: uuidv4(),
    order: "09",
    title: "medicalManagement",
  },
  {
    id: uuidv4(),
    order: "10",
    title: "optometrist",
  },
  {
    id: uuidv4(),
    order: "11",
    title: "blindnesstest",
  },
  {
    id: uuidv4(),
    order: "12",
    title: "visualSkills",
  },
  {
    id: uuidv4(),
    order: "13",
    title: "visionTests",
  },
  {
    id: uuidv4(),
    order: "14",
    title: "ergonomicEvaluation",
  },
  {
    id: uuidv4(),
    order: "15",
    title: "ambulance",
  },
  {
    id: uuidv4(),
    order: "16",
    title: "medicalInsurance",
  },
];