import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import AliceCarousel from 'react-alice-carousel';

import { TRANSLATIONS_OPTIONS } from '../../shared/constants';

import niosh from '../../media/img/certifications/NIOSH.jpeg';
import caohc from '../../media/img/certifications/CAOHC.jpeg';
import valencia from '../../media/img/certifications/UNI_VALENCIA.jpeg';

import imagePrevArrow from '../../media/icons/icon-left.svg';
import imageNextArrow from '../../media/icons/icon-right.svg';

import styles from './certifications.module.scss';

const CERTIFICATIONS_LIST = [
  {
    id: uuidv4(),
    title: 'certifications.niosh.title',
    subtitle: 'certifications.niosh.subtitle',
    img: niosh,
    imgWidth: 200,
  },
  {
    id: uuidv4(),
    title: 'certifications.caohc.title',
    subtitle: 'certifications.caohc.subtitle',
    img: caohc,
    imgWidth: 100,
  },
  {
    id: uuidv4(),
    title: 'certifications.valencia.title',
    img: valencia,
    imgWidth: 300,
  },
];

export default function Certifications({windowWidth}) {
  const { t, i18n } = useTranslation('global');
  const currentTranslation = i18n.language;

  const listItems = CERTIFICATIONS_LIST.map(item => (
    <div key={item.id} className={styles['certifications__item']}>
      <img 
        src={item.img}
        alt='certification'
        width={item.imgWidth}
        className={styles['certifications__image']}
      />

      <p className={styles['certifications__content']}>{t(`${item.title}`)}</p>
      {currentTranslation === TRANSLATIONS_OPTIONS.ES && item.subtitle ? 
        <p className={styles['certifications__content']}>{t(`${item.subtitle}`)}</p>
        : null
      }
    </div>
  ));

  const responsive = {
    0: {
      items: 1
    },
    700: {
      items: 2
    }
  };

  return (
    <div className={styles.certifications} id="certifications">
      <h3 
        className={styles['certifications__title']}
      >
        {t('certifications.title')}
      </h3>
      <div className={styles['certifications__container']}>
        {windowWidth > 800 ?
          listItems :
          <AliceCarousel
            disableDotsControls
            infinite
            items={listItems}
            responsive={responsive}
            renderPrevButton={() => {
              return (<img className={styles.controls} src={imagePrevArrow} height={35} alt="prev"/>)
            }}
            renderNextButton={() => {
              return (<img className={styles.controls} src={imageNextArrow} height={35} alt="next"/>)
            }}
          />
        }
      </div>
    </div>
  )
}
