import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Button from '../../shared/button/button';

import { WINDOW_SIZE } from '../../shared/constants';

import styles from './nav.module.scss';

function Nav({windowSize, handleChecked= () => null, vertical = false}) {
  const { t } = useTranslation('global');
  return (
    <div className={clsx(styles.nav, {
      [styles.vertical]: vertical
    })}>
      {MENU_LIST.map((menu) => (
        <div 
          key={menu.id}
          className={clsx(styles.container, {
          [styles['vertical__button']]: vertical
          })}
        >
          <a href={menu.url} className={styles.link}>
            <Button
              kind="ghost"
              size={windowSize === WINDOW_SIZE.large ? "medium" : "small"}
              key={menu.id}
              hoverCyan={!vertical}
              action={() => handleChecked()}
            >
              {t(`header.menu.${menu.name}`)}
            </Button>
          </a>
        </div>
      ))}
    </div>
  )
}

export default Nav

const MENU_LIST = [
  {
    id: uuidv4(),
    url: '#about-us',
    name: 'us'
  },
  {
    id: uuidv4(),
    url: '#services',
    name: 'services'
  },
  {
    id: uuidv4(),
    url: '#packages',
    name: 'packages'
  },
  {
    id: uuidv4(),
    url: '#contact',
    name: 'contact'
  },
  {
    id: uuidv4(),
    url: '#strengths',
    name: 'strengths'
  },
  {
    id: uuidv4(),
    url: '#certifications',
    name: 'certifications'
  },
];